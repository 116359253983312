.main {
  padding: 30px 0px;
  padding-bottom: 0px;
  width: 100%;
  background: #0e1424;
  position: sticky;
  bottom: 0;
  font-weight: 400;
}

.main li,
.main a {
  list-style: none;
  vertical-align: middle;
  font-weight: 700;
  color: #666666;
}

.main a:hover {
  color: #6f389f;
  text-decoration: none;
}

.caption {
  margin-top: 20px;
  color: #575757;
}

.refs a {
  width: 32px;
  color: #fafafa;
  display: inline-grid;
  text-align: center;
  height: 32px;
  margin: 3px;
  align-content: center;
  font-size: 35px;
  display: inline-block;
  text-decoration: none;
}

.refs a:hover {
  color: #fafafa;
}
