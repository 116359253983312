.main h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 22px;
}

@media (min-width: 992px) {
  .main h1 {
    font-size: 50px;
  }
}
