@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  scroll-behavior: smooth !important;
}

body {
  font-family: "Ubuntu", sans-serif !important;
  overflow-x: hidden !important;
  margin: 80px 0px 0px 0px !important;
}

p {
  font-family: "Barlow", sans-serif;
}

hr {
  border-top: 2px solid #fafafa;
  margin: 50px auto;
  width: 80%;
}

@media (min-width: 992px) {
  hr {
    margin: 100px auto !important;
  }
}
@media screen and (max-width: 375px) {
  body {
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden !important;
  }
}
